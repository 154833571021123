import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {
    Button,
    Grid,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    Box,
    TextField,
    TextareaAutosize,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import instance, { BASE_URL_PROJECT } from "../../../../../api/config";
import {
    handleLoadingSpinnerClose,
    handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { useSelector } from "react-redux";

const ViewProjectDetail = (props) => {
    const { classes, textMainFieldChangeHandler } = props;
    const dispatch = useDispatch();
    const { projectId } = useParams();
    const [projectDetail, setProjectDetail] = useState(null);
    const [creditSaleDetail, setCreditSaleDetail] = useState([]);
    const [impactDimension, setImpactDimension] = useState([]);
    const [projectLocation, setProjectLocation] = useState([]);
    const [sustainableDevelopmentGoal, setSustainableDevelopmentGoal] =
        useState([]);
    const [adminRemark, setAdminRemark] = useState(null);
    const [gfwMapLink, setGfwMapLink] = useState(null);

    const fields = useSelector(
        ({ PROJECT }) => PROJECT.forms.projectForm.fields
    );

    useEffect(() => {
        const getData = async () => {
            dispatch(
                handleLoadingSpinnerOpenWithOptions(
                    true,
                    "Loading Project Detail",
                    "small"
                )
            );
            await instance
                .get(
                    BASE_URL_PROJECT +
                        `/project/onboarding/findProjectById?projectId=${projectId}`
                )
                .then((res) => {
                    let creditSaleDetail = res.data.creditSaleDetail.map(
                        (item, index) => {
                            return [
                                item.initialQuantity,
                                item.vintage,
                                item.vcsSerialNumber,
                                item.pricePerCredit,
                                item.quantity,
                                res.data.vintageFiles[index]?.fileUrl,
                                res.data.vintageFiles[index]?.ipfsFileHash,
                                item.transactionTxId,
                            ];
                        }
                    );
                    let impactDimension = res.data.impactDimension.map(
                        (item) => {
                            return [item.imageUrl, item.detail, item.score];
                        }
                    );
                    let projectLocation = [res.data.projectLocation].map(
                        (item) => {
                            return [
                                item.addressLine1,
                                item.addressLine2,
                                item.city,
                                item.country,
                                item.zipCode,
                            ];
                        }
                    );
                    let sustainableDevelopmentGoal =
                        res.data.sustainableDevelopmentGoal.map((item) => {
                            return [item.cobenefit, item.moreDetail];
                        });
                    setProjectDetail(res.data);
                    setGfwMapLink(res.data.gfwMapLink);
                    setCreditSaleDetail(creditSaleDetail);
                    setImpactDimension(impactDimension);
                    setProjectLocation(projectLocation);
                    setSustainableDevelopmentGoal(sustainableDevelopmentGoal);
                })
                .catch((err) => {});
            dispatch(handleLoadingSpinnerClose());
        };
        getData();
    }, [projectId]);

    const getFileName = (fileUrl) => {
        if (!fileUrl) {
            return null;
        }
        const DOMAIN_END = "org/";
        const DELIMETER = "?";
        const startIndex = fileUrl.indexOf(DOMAIN_END);
        const endIndex = fileUrl.indexOf(DELIMETER);
        const fileName = fileUrl.substring(
            startIndex + DOMAIN_END.length,
            endIndex
        );
        return fileName;
    };

    const viewDocument = (fileName) => {
        window.open(fileName.fileUrl, "_blank", "noopener,noreferrer");
    };

    const remarksHandler = () => {
        instance
            .post(
                BASE_URL_PROJECT +
                    `/project/onboarding/admin-remarks?adminRemarks=${adminRemark}&projectId=${parseInt(
                        projectId
                    )}`
            )
            .then((res) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error.message);
            });
    };

    const gfwMapLinkHandler = () => {
        const pattern =
            /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;

        if (gfwMapLink === null || gfwMapLink === "") {
        } else {
            if (pattern.test(gfwMapLink)) {
            } else {
                alert("The GFW Map link is not valid");
                return;
            }
        }

        instance
            .post(
                BASE_URL_PROJECT +
                    `/project/onboarding/gfw-map-link?gfwMapLink=${gfwMapLink}&projectId=${parseInt(
                        projectId
                    )}`
            )
            .then((res) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error.message);
            });
    };

    const saleDetailsColumns = [
        "Initial Quantity",
        "Vintage",
        "Serial Number",
        "Price Per Credit",
        "Current Balance",
        "URI",
        "Partition-ID",
        "Transaction Hash",
    ];
    const impactDimensionColumns = ["Image Url", "Detail", "Score"];
    const projectLocationColumns = [
        "Address 1",
        "Address 2",
        "City",
        "Country",
        "Zip Code",
    ];
    const sustainableDevelopmentGoalColumns = ["Cobene Fit", "More Detail"];

    return (
        projectDetail && (
            <div className={classes.mainrapper}>
                <h1>Project Detail</h1>
                <div className={classes.detailHeader}>
                    <h1>Name: {projectDetail?.projectName}</h1>
                    <p>
                        Location:{" "}
                        {`${projectDetail.projectLocation.addressLine1} ${projectDetail.projectLocation.city} ${projectDetail.projectLocation.country}`}
                    </p>
                </div>

                <div className={classes.specs}>
                    <div className={"specs-details"}>
                        <div className="specs">
                            <span className="title">Specifications</span>
                            <div className="specs-list-item">
                                <span>Standard:</span>
                                {projectDetail.projectStandard.map((item) => (
                                    <span key={item}>{item}</span>
                                ))}
                            </div>
                            <div className="specs-list-item">
                                <span>Ecosystem:</span>
                                <span>{projectDetail.ecosystem}</span>
                            </div>
                            <div className="specs-list-item">
                                <span>Project Type:</span>
                                <span>{projectDetail.projecType}</span>
                            </div>
                            <div className="specs-list-item">
                                <span>
                                    Estimated CO2 Emission Reductions or
                                    Removals:
                                </span>
                                <span>{projectDetail.estimatedEmission}</span>
                            </div>
                            <div className="specs-list-item">
                                <span>Serial Number:</span>
                                <span>Methodoloy applied</span>
                            </div>
                            <div className="specs-list-item">
                                <span>CO2 Outcome:</span>
                                <span>{projectDetail.titleOfMethodology}</span>
                            </div>
                        </div>
                        <div className="biography">
                            <span className="title">Biography</span>
                            <p>{projectDetail.projectBiography}</p>
                        </div>
                        <div className="special_remarks">
                            <Grid container>
                                <Grid item xs={6} md={6}>
                                    <span className="title">
                                        Special Remark
                                    </span>
                                    <div className="fieldwrapper">
                                        <TextareaAutosize
                                            id="outlined-basic"
                                            aria-label="minimum height"
                                            minRows={3}
                                            value={
                                                adminRemark !== null
                                                    ? adminRemark.slice(0, 1000)
                                                    : projectDetail.adminSpecialRemark.slice(
                                                          0,
                                                          1000
                                                      )
                                            }
                                            onChange={(e) =>
                                                setAdminRemark(e.target.value)
                                            }
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="submitbtn"
                                            type="submit"
                                            onClick={() => remarksHandler()}
                                        >
                                            {projectDetail.adminSpecialRemark
                                                ? "Update"
                                                : "Add"}
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={6} md={6}>
                                        <span className="title gfwMap">
                                            GFW Map Link
                                        </span>
                                        <div className="fieldwrapper">
                                            <TextareaAutosize
                                                id="outlined-basic"
                                                aria-label="minimum height"
                                                minRows={3}
                                                maxRows={10}
                                                maxLength={2000}
                                                value={
                                                    gfwMapLink !== null
                                                        ? gfwMapLink.slice(
                                                              0,
                                                              2000
                                                          )
                                                        : projectDetail.gfwMapLink
                                                }
                                                onChange={(e) => {
                                                    setGfwMapLink(
                                                        e.target.value
                                                    );
                                                }}
                                                style={{
                                                    resize: "none",
                                                    overflow:
                                                        "overflow-y: scroll;",
                                                    height: "60px",
                                                }}
                                            />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="submitbtn"
                                                type="submit"
                                                onClick={gfwMapLinkHandler}
                                            >
                                                {projectDetail.gfwMapLink
                                                    ? "Edit"
                                                    : "Edit"}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className={classes.documents}>
                    <span className="title">Documents</span>
                    {projectDetail.legalDocuments.map((item, index) => (
                        <div key={index} className="documentList">
                            <span>{getFileName(item.fileUrl)}</span>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => viewDocument(item)}
                            >
                                View Document
                            </Button>
                        </div>
                    ))}
                </div>

                <br />
                <br />
                <br />

                <MUIDataTable
                    title={"Credit Sale Detail"}
                    data={creditSaleDetail}
                    columns={saleDetailsColumns}
                    // options={options}
                />
                <br />
                <br />

                <MUIDataTable
                    title={"Impact Dimension"}
                    data={impactDimension}
                    columns={impactDimensionColumns}
                    // options={options}
                />
                <br />
                <br />

                <MUIDataTable
                    title={"Project Location"}
                    data={projectLocation}
                    columns={projectLocationColumns}
                    // options={options}
                />
                <br />
                <br />

                <MUIDataTable
                    title={"Sustainable Development Goal"}
                    data={sustainableDevelopmentGoal}
                    columns={sustainableDevelopmentGoalColumns}
                    // options={options}
                />
            </div>
        )
    );
};
export default withStyles(styles)(ViewProjectDetail);
