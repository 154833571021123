import React, { Component, useState } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Button, TextareaAutosize, Grid, TextField } from "@material-ui/core";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instance, { BASE_URL_PROJECT } from "../../../../../api/config";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import { WHITELIST } from "../../../../WEB3/components/WhiteListContract";
import { CARBON } from "../../../../WEB3/components/CarbonContract";

toast.configure();

const EditForm = ({ data, classes, fetchDataIfNeeded, onClose }) => {
  const { id, message } = data;
  const [value, setValue] = useState(message || "");
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const res = await instance.post(
        `${BASE_URL_PROJECT}/content-mangement/fee/create`,
        { message: value }
      );
      fetchDataIfNeeded();
      onClose();
    } catch (ex) {
      console.error(ex.message);
      alert(ex.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <TextareaAutosize
        className={classes.textarea}
        minRows={6}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        disabled={isLoading}
        style={{
          background: "#1D5485",
          color: "white",
          margin: "auto",
          marginTop: "10px",
          display: "block",
        }}
        onClick={handleSave}
      >
        {isLoading ? "Please wait..." : "Save"}
      </Button>
    </div>
  );
};

class ViewAllProjects extends Component {
  state = {
    showEdit: false,
    selectedContent: null,
    editableRow: [],
    changedValues: [],
  };

  handleClick = (action, event, dataValue, rowData) => {
    if (action === 'save') {
      const message = this.state.changedValues.filter((item) => item.label === dataValue)[0];
      const data = {
        message: message.message
      };
      instance
        .post(`${BASE_URL_PROJECT}/content-mangement/fee/create?fundingSource=${dataValue}`, data)
        .then((res) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }

    this.setState((state) => {
      const filteredEditableRowArr = state.editableRow.filter(
        (item) => item !== dataValue
      );
      if (action === "save") {
        return {
          editableRow: [...filteredEditableRowArr],
        };
      }

      return {
        editableRow: [...state.editableRow, dataValue],
      };
    });
  };

  hideEditForm = () => {
    this.setState({
      showEdit: false,
    });
  };

  componentDidMount = () => {
    this.props.fetchDataIfNeeded();
  };

  changeHandler = (val, field) => {
    this.setState((state) => {
      const updatedChangedValues = [...state.changedValues];
      const selectedValueIndex = updatedChangedValues.findIndex((item) => item.label === field);
      if (selectedValueIndex > -1) {
        updatedChangedValues[selectedValueIndex] = {
          label: field,
          message: val,
        }
      } else {
        updatedChangedValues.push({
          label: field,
          message: val,
        });
      }

      return {
        changedValues: updatedChangedValues,
      }
    });

    const updatedStateValues = [...this.props.allContent];
    const selectedValueIndex = updatedStateValues.findIndex((item) => item.fundingSource === field);
    if (selectedValueIndex > -1) {
      updatedStateValues[selectedValueIndex].message = val;
    }

    this.props.updateValues(updatedStateValues);
  };

  render() {
    const { classes, allContent, fetchDataIfNeeded, whiteListContractAddress } =
      this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    const columns = [
      {
        name: "fundingSource",
        label: "Financal Product",
      },
      {
        name: "message",
        label: "Current message",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            const editState = this.state.editableRow.includes(rowDataValue);
            return editState ? (
                <textarea 
                  value={props ? props : ""}
                  onChange={(e) => {
                    this.changeHandler(e.target.value, rowDataValue);
                  }}
                  className={classes.textarea}
                />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: props }}
                style={{ whiteSpace: "pre-wrap" }}
              />
            );
          },
        },
      },
      {
        name: "Action",
        label: "Action",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            let rowData = data.rowData;
            const { selectedContent } = this.state;
            const editState = this.state.editableRow.includes(rowDataValue);
            return (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) =>
                    this.handleClick(
                      editState ? "save" : "edit",
                      e,
                      rowDataValue,
                      rowData
                    )
                  }
                >
                  {editState ? "Save" : "Edit"}
                </Button>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      selectableRows: false,
    };

    return (
      <>
        {this.state.showEdit && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showEdit}
            showTitle={true}
            title={"Edit Fee Text"}
            message={
              <EditForm
                data={this.state.selectedContent}
                classes={classes}
                fetchDataIfNeeded={fetchDataIfNeeded}
                onClose={this.hideEditForm}
              />
            }
            cancelButtonText={"Close"}
            hideApplyButton={true}
            cancelAlertDialog={this.hideEditForm}
            closeAlertDialog={this.hideEditForm}
          />
        )}

        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <p>Content Management / Project Sale / Fee message</p>
            <MUIDataTable
              title="Fee Text - Purchase Credits"
              data={allContent}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(ViewAllProjects);
