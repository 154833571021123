export const LANDING_DASHBOARD = {
    data: {
        dashboardCount: ''
    },
    forms: {
    },
    UISettings: {
        
    }
}
export default LANDING_DASHBOARD;