import { connect } from "react-redux";
import UserDashboard from "./component";
import instance from "../../../api/config";
import { BASE_URL_BLOCKCHAIN } from "../../../api/config";
import { handleLoadingSpinnerOpenWithOptions } from "../../common/ReduxBased/LoadingSpinner/actions";
import { handleLoadingSpinnerClose } from "../../common/ReduxBased/LoadingSpinner/actions";
import { saveAllContracts } from "./BlockChainManagement/actions";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: () => {},
  fetchContracts: async () => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Loading Smart Contracts",
        "small"
      )
    );
    await instance
      .get(BASE_URL_BLOCKCHAIN + "/contract/list")
      .then((res) => {
        const contractsList = [...res.data];
        Promise.all(
          contractsList.map(async (item, index) => {
            await instance
              .get(
                BASE_URL_BLOCKCHAIN + "/contract-address?contractId=" + item.id
              )
              .then((cRes) => {
                if (cRes && cRes.data?.address) {
                  contractsList[index].status = "Deployed";
                  contractsList[index].address = cRes.data.address;
                } else {
                  contractsList[index].status = "Not deployed";
                }
              });
          })
        ).then(() => {
          dispatch(saveAllContracts(contractsList));
        });
      })
      .catch((err) => {});
    dispatch(handleLoadingSpinnerClose());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UserDashboard);
