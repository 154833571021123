import { connect } from "react-redux";
import ViewAllInvestments from "./component";
import instance, { BASE_URL, BASE_URL_PROJECT } from "../../../../../api/config";
import {
  saveALLInvestments,
  editSellerChangeHandler,
  saveAddSellerResponse,
} from "../actions";
import ls from "local-storage";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";
import { getWhiteListAddress } from "../../BlockChainManagement/ViewAllContracts/container";

const mapStateToProps = (state) => ({
  allSellers: state.SELLER.data.allSellers,
  sellerAddStatus: state.SELLER.data.sellerAddStatus,
  addSellerResponseMsg: state.SELLER.data.addSellerResponseMsg,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
  whiteListContractAddress: getWhiteListAddress(
    state.CONTRACTS.data?.list ?? []
  ),
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: () => {
    instance
      .get(BASE_URL_PROJECT + "/project/onboarding/all-transaction-history")
      .then((res) => {
        dispatch(saveALLInvestments(res.data));
      })
      .catch((err) => {});
  },
  deleteSellerHandler: (id) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Deleting Buyer Profile",
        "small"
      )
    );
    instance
      .delete(BASE_URL + "/buyer/api/delete-buyer/" + id)
      .then((res) => {
        dispatch(handleLoadingSpinnerClose());
        window.location.reload();
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  editSellerChangeHandler: (value, name) => {
    dispatch(editSellerChangeHandler(value, name));
  },

  resendInvitation: (username, email) => {
    let data = {
      eBayUserName: username,
      email: email,
    };

    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Resend Invitation", "small")
    );
    //todo STAGING, DEV, PROTOTYPE, LIVE

    instance
      .post(BASE_URL + "auction-seller/addSeller?env=STAGING", data)
      .then((res) => {
        ls.set("addsellerToken", res.data.data.sessionId);
        ls.set("addsellerEmail", res.data.data.userId);
        dispatch(saveAddSellerResponse(res.data, res.data.description));
        // window.location.replace('/user/view-seller')
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(checkIfUrlIsAllowed("buyerService"));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ViewAllInvestments);
