import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
} from "@material-ui/core";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorMessage from "../../common/ErrorMessage/component";
import instance, { BASE_URL_PROJECT } from "../../../../../api/config";
import ChangeCompany from "./changeCompany";
import Whitelister from "../../../../WEB3/components/Whitelister";

toast.configure();

const ChangeBuyerStatus = (props) => {
  const [value, setValue] = React.useState(props.status || "");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const { popupStatusId, transactionId, projectId } = props;

  const data = {
    params: {
      onboardingProjectId: projectId,
      transactionId: transactionId
    }
  }

  const handleSave = () => {
    instance
      .put(BASE_URL_PROJECT + "/project/onboarding/transaction-approved-status-update-by-admin", {}, data)
      .then((res) => {
        window.location.replace("/user/all-investments");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <Grid item xs={12} md={12}>
      <div style={{ textAlign: "center" }}>
        <InputLabel id="projectStatusLabel">Select Status</InputLabel>
        <Select
          labelId="projectStatusLabel"
          id="projectStatus"
          value={value}
          onChange={handleChange}
        >
          <MenuItem value={"APPROVED"}>
            APPROVED
          </MenuItem>
        </Select>
      </div>
      {!props.selectedIds.length ||
        (!popupStatusId && (
          <div style={{ color: "red", fontSize: "12px", textAlign: "center" }}>
            Please select rows from table
          </div>
        ))}
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ background: "#1D5485", color: "white", marginTop: "10px" }}
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </div>
    </Grid>
  );
};

class ViewAllInvestments extends Component {
  state = {
    show: false,
    showDelete: false,
    showProjects: false,
    showUsers: false,
    showSustainability: false,
    changeBuyerStatus: false,
    selectedSellerId: "",
    showChangeCompanyPopup: false,
    currentId: "",
    currentEmail: "",
    anchorEl: null,
    resendebayUsername: "",
    resendebayEmail: "",
    popupStatusId: "",
    projectId: "",
    transactionId: "",
  };

  showAlertDialog = (value, id, data) => {
    this.setState({
      show: value,
      currentId: id,
      // showEdit:value,
      currentEmail: data[2],
    });
  };

  showProjectsAlertDialog = (value, id, data) => {
    this.setState({
      showProjects: value,
      currentId: id,
      currentEmail: data[2],
    });
  };

  showSustainabilityAlertDialog = (value, id, data) => {
    this.setState({
      showSustainability: value,
      currentId: id,
      currentEmail: data[2],
    });
  };

  showUserAlertDialog = (value, id, data) => {
    this.setState({
      showUsers: value,
      currentId: id,
      currentEmail: data[2],
    });
  };

  handleClick = (event, dataValue, rowData) => {
    // this.setState({
    //   anchorEl: event.currentTarget,
    //   currentId: dataValue,
    //   editData: rowData,
    //   resendebayUsername: rowData[3],
    //   resendebayEmail: rowData[4],
    //   tanganyAddress: rowData[7],
    //   status: rowData[6],
    // });

    const data = {
      params: {
        onboardingProjectId: rowData[1],
        transactionId: rowData[0]
      }
    }
  
    instance
      .put(BASE_URL_PROJECT + "/project/onboarding/transaction-approved-status-update-by-admin", {}, data)
      .then((res) => {
        window.location.replace("/user/all-investments");
      })
      .catch((error) => {
        console.log(error.message);
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialogDelete = (value, id) => {
    this.setState({
      showDelete: value,
      currentId: id,
    });
  };

  showAlertDialogClosed = (value, id) => {
    this.setState({
      show: value,
      showProjects: value,
      showUsers: value,
      showSustainability: value,
    });
  };

  deleteProfileHandler = () => {
    this.setState({
      showDelete: false,
    });
    this.props.deleteSellerHandler(this.state.currentId);
  };

  editCompany = (show, id, editData) => {
    console.log(show, id);
    this.setState({
      selectedSellerId: id,
      showChangeCompanyPopup: show,
    });
  };

  editHandler = (show, id, editData) => {
    this.props.editSellerChangeHandler(id, editData);
    this.setState({
      showEdit: show,
    });
  };

  showEditSellerDialog = (value) => {
    this.setState({
      showEdit: value,
    });
  };

  resendInvitationHandler = (username, email) => {
    this.props.resendInvitation(username, email);
  };

  componentDidMount = () => {
    this.props.checkIfUrlIsAllowed();
    this.props.fetchDataIfNeeded();
  };

  selectedIds = [];
  customToolbarSelect = (selectedRows, displayData) => {
    let ids = [];
    selectedRows.data.map((value, index) => {
      let selectedValue = displayData.filter(
        (_, dataIndex) => dataIndex === value.dataIndex
      );
      ids.push(selectedValue[0].data[0]);
    });
    this.selectedIds = ids;
  };

  render() {
    const {
      classes,
      allSellers,
      sellerAddStatus,
      addSellerResponseMsg,
      isAllowed,
      whiteListContractAddress,
    } = this.props;
    const {
      currentId,
      editData,
      resendebayUsername,
      resendebayEmail,
      tanganyAddress,
      status,
    } = this.state;
    const open = Boolean(this.state.anchorEl);

    const id = open ? "simple-popover" : undefined;

    const columns = [
      "ID",
      "Project ID",
      "User ID",
      "Quantity",
      "Investment Amount",
      "Premium Amount",
      "Total Investment Amount",
      "Total Quantity",
      "Vintage",
      "Status",
      {
        name: "Action",
        label: "Action",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            let rowData = data.rowData;
            return (
              <div>
                {
                  rowData[9] === "APPROVED" ? "Approved" : rowData[9] === "TRANSFERED" ?  'Transfered' :
                  <Button
                  aria-describedby={id}
                  variant="contained"
                  color="primary"
                  onClick={(e) => this.handleClick(e, rowDataValue, rowData)}
                >
                  Approve
                </Button>
                }
                
                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className={classes.actions}>
                    <Whitelister
                      status={status}
                      onWhiteList={this.props.fetchDataIfNeeded}
                      sellerId={currentId}
                      isWhitelist={true}
                      whiteListContractAddress={whiteListContractAddress}
                      tanganyAddress={tanganyAddress}
                    />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.setState({
                          changeBuyerStatus: !this.state.changeBuyerStatus,
                          popupStatusId: editData[0],
                          projectId: editData[1],
                          transactionId: editData[0],
                        })
                      }
                    >
                      Change Status
                    </Button>
                  </div>
                </Popover>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      // filterType: 'checkbox',
      customToolbarSelect: this.customToolbarSelect,
    };

    return (
      <>
        {this.state.showDelete && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showDelete}
            showTitle={true}
            title={"Delete Buyer"}
            message={"Are you sure want to delete this buyer?"}
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogDelete(false)}
            closeAlertDialog={() => this.showAlertDialogDelete(false)}
            applyAlertDialog={() => this.deleteProfileHandler()}
          />
        )}
        {this.state.showChangeCompanyPopup && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showChangeCompanyPopup}
            showTitle={true}
            title={"Change Company"}
            message={
              <ChangeCompany selectedIds={this.state.selectedSellerId} />
            }
            hideApplyButton={true}
            cancelButtonText={"Close"}
            cancelAlertDialog={() =>
              this.setState({
                showChangeCompanyPopup: !this.state.showChangeCompanyPopup,
              })
            }
            closeAlertDialog={() =>
              this.setState({
                showChangeCompanyPopup: !this.state.showChangeCompanyPopup,
              })
            }
            applyAlertDialog={() => this.showEditSellerDialog(false)}
          />
        )}
        {this.state.changeBuyerStatus && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.changeBuyerStatus}
            showTitle={true}
            title={"Edit Status"}
            message={
              <ChangeBuyerStatus
                selectedIds={this.selectedIds}
                popupStatusId={this.state.popupStatusId}
                status={this.state.status}
                projectId={this.state.projectId}
                transactionId={this.state.transactionId}
              />
            }
            hideApplyButton={true}
            cancelButtonText={"Close"}
            cancelAlertDialog={() =>
              this.setState({
                changeBuyerStatus: !this.state.changeBuyerStatus,
              })
            }
            closeAlertDialog={() =>
              this.setState({
                changeBuyerStatus: !this.state.changeBuyerStatus,
              })
            }
            applyAlertDialog={() => this.showEditSellerDialog(false)}
          />
        )}

        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            {addSellerResponseMsg ? (
              <Alert severity="success">{sellerAddStatus}</Alert>
            ) : null}
            <div style={{ float: "right" }}>
            </div>
            <p>View all Investments</p>
            <MUIDataTable
              title={"Investments Details"}
              data={allSellers}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(ViewAllInvestments);
