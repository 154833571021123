export const SAVE_ALL_CONTENT = "SAVE_ALL_CONTENT";
export const SAVE_PREMIUM_AMOUNT = "SAVE_PREMIUM_AMOUNT";

export const saveAllContent = (value) => (dispatch) => {
  dispatch({
    type: SAVE_ALL_CONTENT,
    payload: value,
  });
};

export const updateContent = (value) => (dispatch) => {
  dispatch({
    type: SAVE_ALL_CONTENT,
    payload: value,
  });
};

export const getPremiumRate = (value) => (dispatch) => {
  let newArray = value[0].premiumRate
  dispatch({
    type: SAVE_PREMIUM_AMOUNT,
    payload: newArray,
  });
};
