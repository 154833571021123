import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import instance, {
  BASE_URL,
  BASE_URL_USER,
} from "../../../../../../api/config";
import { NavLink } from "react-router-dom";
import { Button, Popover } from "@material-ui/core";
import AlertDialog from "../../../../../common/ReduxBased/confirmationDialog/container";
import { Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../../common/ReduxBased/LoadingSpinner/actions";

class ViewAllUserDetails extends Component {
  state = {
    allDetails: [],
    show: false,
    showDelete: false,
    currentId: "",
    anchorEl: null,
    editData: "",
    companyDetails: null,
  };
  handleClick = (event, dataValue, rowData) => {
    this.setState({
      anchorEl: event.currentTarget,
      currentID: dataValue,
      editData: rowData,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialog = (value, id) => {
    this.setState({
      show: value,
      currentId: id,
    });
  };
  showAlertDialogClose = (value, id) => {
    this.setState({
      showEdit: value,
    });
  };

  showAlertDialogDelete = (value, id) => {
    this.setState({
      showDelete: value,
      currentId: id,
    });
  };

  editHandler = (show, id, current_id, editData) => {
    // this.props.editViewBiddingChangeHandler(id,current_id,editData)
    this.setState({
      showEdit: show,
    });
  };

  deleteProfileHandler = (id) => {
    this.setState({
      showDelete: false,
    });

    const stateCurrentId = this.state.currentId;
    const propsCurrentId = this.props.currentId;
  };

  fetchCompanyDetails = (email) => {
    if (!email) {
      return false;
    }
    instance
      .get(
        `${BASE_URL_USER}/user-management/get-company-profile-by-email?email=${email}`
      )
      .then((res) => {
        this.setState({ companyDetails: res.data.data });
      });
  };

  componentDidMount = () => {
    let data = {
      params: {
        id: this.props.currentId,
      },
    };
    instance
      .get(BASE_URL + "/seller/api/sellerById", data)
      .then((res) => {
        this.setState({
          allDetails: res.data,
        });
        this.fetchCompanyDetails(res.data.email);
      })
      .catch((err) => {});
  };

  viewTimelines = (value) => {};

  showEditViewBiddingDialog = (value) => {
    this.setState({
      showEdit: value,
    });
  };

  render() {
    console.log("state", this.state);
    const { classes, currentId, currentEmail } = this.props;
    const { allDetails, editData, companyDetails } = this.state;

    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    const columns = [
      "Company Name",
      "Website URL",
      "Phone number",
      "Email contact",
      "City",
      "Country",
      "Company Bio",
    ];

    const options = {};

    return (
      <div className={classes.editmainrapper}>
        <div className={classes.editfieldswrapper}>
          <p>View All User Details</p>

          <ValidatorForm onSubmit={this.SubmitHandler} autoComplete="off">
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"User ID"}</span>
                  <div className={classes.fieldsdata}>{allDetails.id}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Type"}</span>
                  <div className={classes.fieldsdata}>
                    {allDetails.accessType}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Title"}</span>
                  <div className={classes.fieldsdata}>{allDetails.title}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"First Name"}</span>
                  <div className={classes.fieldsdata}>
                    {allDetails.familyName}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Company Name"}</span>
                  <div className={classes.fieldsdata}>
                    {allDetails.companyName}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Last Name"}</span>
                  <div className={classes.fieldsdata}>{allDetails.surName}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Email"}</span>
                  <div className={classes.fieldsdata}>{allDetails.email}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Email Verified"}</span>
                  <div className={classes.fieldsdata}>
                    {String(allDetails.emailVarified)}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Phone Number"}</span>
                  <div className={classes.fieldsdata}>
                    {allDetails.phonenumber}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Status"}</span>
                  <div className={classes.fieldsdata}>{allDetails.status}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Vat No"}</span>
                  <div className={classes.fieldsdata}>{allDetails.vatNo}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Newsletter Subscribe"}</span>
                  <div className={classes.fieldsdata}>
                    {String(allDetails.newsletterSubscribe)}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Address 1"}</span>
                  <div className={classes.fieldsdata}>
                    {allDetails.addressLine1 + ", " + allDetails.addressLine2}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Address 2"}</span>
                  <div className={classes.fieldsdata}>
                    {companyDetails?.streetNumber ? companyDetails?.streetNumber : ""}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"city"}</span>
                  <div className={classes.fieldsdata}>{allDetails.city}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"country"}</span>
                  <div className={classes.fieldsdata}>{allDetails.country}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Zip Code"}</span>
                  <div className={classes.fieldsdata}>
                    {parseFloat(allDetails?.zipCode) ? parseFloat(allDetails?.zipCode) : parseFloat(companyDetails?.zipcode) ? parseFloat(companyDetails?.zipcode) : ""}
                  </div>
                </div>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ViewAllUserDetails);
