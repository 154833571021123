import { SAVE_ALL_CONTENT, SAVE_PREMIUM_AMOUNT } from "./actions";

export const contentReducer = (state = [], action) => {
  switch (action.type) {
    case SAVE_ALL_CONTENT: {
      return {
        ...state,
        data: {
          ...state.data,
          list: [...action.payload],
        },
      };
    }
    case SAVE_PREMIUM_AMOUNT: {
      return {
        ...state,
        data: {
          ...state.data,
          premiumRate: action.payload,
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
