import BG from "../../../../../resources/images/landingbg.svg";

export const styles = (theme) => ({
  mainrapper: {
    background: `url(${BG})`,
    backgroundSize: "cover",
    padding: "32px 13px",
    minHeight: "calc(100vh - 115px)",
    width: "100%",
    "& th:last-child": {
      width: "200px",
    },
  },
  fieldswrapper: {
    background: "white",
    padding: 38,
    margin: "0px 38px",
    "& p": {
      color: "#707070",
    },
  },
  textarea: {
    width: "100%",
    minHeight: "200px",
    resize: "none",
    padding: "10px",
  },
  actions: {
    display: "block",
    padding: 7,
    minWidth: 100,
    "& button": {
      display: "block",
      width: "100%",
      marginBottom: 11,
    },
  },
});
