import React, { Component, useState } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Button, TextareaAutosize, Grid } from "@material-ui/core";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instance, { BASE_URL_PROJECT } from "../../../../../api/config";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import { WHITELIST } from "../../../../WEB3/components/WhiteListContract";
import { CARBON } from "../../../../WEB3/components/CarbonContract";
toast.configure();

class PremiumRate extends Component {
  state = {
    showEdit: false,
    selectedContent: null,
    editableRow: [],
    changedValues: [],
    PremiumRate: "",
  };

  handleClick = async (action, event, dataValue, rowData) => {
    try {
      if (action === "save") {
        const amount = this.state.changedValues.filter(
          (item) => item.label === dataValue
        )[0];
        const data = {
          premiumRate: amount.amount,
        };

        const res = await instance.post(`${BASE_URL_PROJECT}/content-mangement/premium/create?fundingSource=${dataValue}`,
          data
        );
        window.location.reload();
      }
      this.setState((state) => {
        const filteredEditableRowArr = state.editableRow.filter(
          (item) => item !== dataValue
        );
        if (action === "save") {
          return {
            editableRow: [...filteredEditableRowArr],
          };
        }

        return {
          editableRow: [...state.editableRow, dataValue],
        };
      });
    } catch (error) {
      console.log(error);
      if (error.response) {
        alert(error?.response?.data?.premiumRate);
      }
    }
  };

  hideEditForm = () => {
    this.setState({
      showEdit: false,
    });
  };

  componentDidMount = () => {
    this.props.fetchDataIfNeeded();
    // this.props.fetchPremiumRate()
  };

  SubmitHandler = () => {
    const premiumRateData = this.props.premiumRateData;

    let data = {
      premiumRate:
        this.state.PremiumRate === ""
          ? premiumRateData
          : this.state.PremiumRate,
    };

    instance
      .post(BASE_URL_PROJECT + "/content-mangement/premium/create", data)
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeHandler = (val, field) => {
    const amount = parseFloat(val.value);

    this.setState((state) => {
      const updatedChangedValues = [...state.changedValues];
      const selectedValueIndex = updatedChangedValues.findIndex(
        (item) => item.label === field
      );
      if (selectedValueIndex > -1) {
        updatedChangedValues[selectedValueIndex] = {
          label: field,
          amount,
        };
      } else {
        updatedChangedValues.push({
          label: field,
          amount,
        });
      }

      return {
        changedValues: updatedChangedValues,
      };
    });

    const updatedStateValues = [...this.props.allContent];
    const selectedValueIndex = updatedStateValues.findIndex(
      (item) => item.fundingSource === field
    );
    if (selectedValueIndex > -1) {
      updatedStateValues[selectedValueIndex].premiumRate = amount;
    }

    this.props.updateValues(updatedStateValues);
  };

  render() {
    const { classes, allContent, premiumRateData, fetchDataIfNeeded } =
      this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    const columns = [
      {
        name: "fundingSource",
        label: "Financal Product",
      },
      {
        name: "premiumRate",
        label: "Rate",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            const editState = this.state.editableRow.includes(rowDataValue);
            return (
              <ValidatorForm onSubmit={this.SubmitHandler} autoComplete="off">
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <div className="fieldwrapper">
                      <CustomTextValidator
                        fullWidth={true}
                        disabled={!editState}
                        type="text"
                        value={props ? props : 0}
                        onChange={(value) => {
                          this.changeHandler(value, rowDataValue);
                        }}
                        className="textfield"
                      />
                    </div>
                  </Grid>
                </Grid>
              </ValidatorForm>
            );
          },
        },
      },
      {
        name: "Action",
        label: "Action",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            let rowData = data.rowData;
            const { selectedContent } = this.state;
            const editState = this.state.editableRow.includes(rowDataValue);
            // console.log(rowDataValue);
            return (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) =>
                    this.handleClick(
                      editState ? "save" : "edit",
                      e,
                      rowDataValue,
                      rowData
                    )
                  }
                >
                  {editState ? "Save" : "Edit"}
                </Button>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      selectableRows: false,
    };

    return (
      <>
        {this.state.showEdit && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showEdit}
            showTitle={true}
            title={"Edit Premium Rate"}
            message={
              <ValidatorForm onSubmit={this.SubmitHandler} autoComplete="off">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <div className="fieldwrapper">
                      <CustomTextValidator
                        fullWidth={true}
                        validations={true}
                        type="number"
                        value={
                          this.state.PremiumRate == ""
                            ? premiumRateData
                            : this.state.premiumRate
                        }
                        onChange={(value) => {
                          this.changeHandler(value);
                        }}
                        InputProps={{
                          inputProps: { min: 0, max: 1, step: "any" },
                        }}
                        helperText={"Length: Minimum: 0 & Maximum: 1"}
                        validators={
                          this.state.PremiumRate == "" ? ["required"] : ""
                        }
                        errorMessages={
                          this.state.PremiumRate == "" ? ["Required"] : ""
                        }
                        className="textfield"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      className="submitbtn"
                      style={{ padding: "12px" }}
                      type="submit"
                    >
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            }
            cancelButtonText={"Close"}
            hideApplyButton={true}
            cancelAlertDialog={this.hideEditForm}
            closeAlertDialog={this.hideEditForm}
          />
        )}

        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <p>View Premium Rate</p>
            <MUIDataTable
              title="Premium Rate"
              data={allContent}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(PremiumRate);
