export const styles = theme => ({
    mainrapper: {
        padding: "15px 10px",
        width: "100%",
    },
    fieldswrapper: {
        background: "white",
        padding: 20,
        "& p": {
            color: "#707070",
        },
        "@media screen and (min-width: 960px)": {
            "& th": {
                width: '150px',
                position: "unset"
            },
            "& th:nth-child(8)": {
                width: "350px",
                position: "unset"
            },
            "& td": {
                position: "unset"
            }
        }, 
    },
    createlink: {
        background: "#074880",
        color: "white",
        textDecoration: "none",
        padding: 8,
        marginBottom: 13,
        display: "block",
        width: 178,
        textAlign: "center"
    }
});