import { connect } from "react-redux";
import PremiumRate from "./component";
import instance, {
  BASE_URL_PROJECT,
  BASE_URL_BLOCKCHAIN,
} from "../../../../../api/config";
import { saveAllContent, getPremiumRate, updateContent } from "../actions";
import ls from "local-storage";
import { WHITELIST } from "../../../../WEB3/components/WhiteListContract";
import { CARBON } from "../../../../WEB3/components/CarbonContract";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

export const getWhiteListAddress = (list) => {
  const item = list.find((f) => f[1] === WHITELIST);
  if (item) {
    return item[3];
  }
  return null;
};
export const getCarbonAddress = (list) => {
  const item = list.find((f) => f[1] === CARBON);
  if (item) {
    return item[3];
  }
  return null;
};

const mapStateToProps = (state) => ({
  allContent: state.CONTENT.data?.list ?? [],
  whiteListContractAddress: getWhiteListAddress(
    state.CONTRACTS.data?.list ?? []
  ),
  premiumRateData: state.CONTENT.data?.premiumRate,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: async () => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Loading Content", "small")
    );
    await instance
      .get(BASE_URL_PROJECT + "/content-mangement/premiums")
      .then((res) => {
        const contentList = res.data.map((item) => {
          return item;
        });
        dispatch(saveAllContent(contentList));
      })
      .catch((err) => {});
    dispatch(handleLoadingSpinnerClose());
  },
  fetchPremiumRate: async () => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Loading Content", "small")
    );
    await instance
      .get(BASE_URL_PROJECT + "/content-mangement/premium")
      .then((res) => {
        const ammount = [res.data];
        dispatch(getPremiumRate(ammount));
      })
      .catch((err) => {});
    dispatch(handleLoadingSpinnerClose());
  },
  updateValues: (value) => {
    dispatch(updateContent(value))
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PremiumRate);
