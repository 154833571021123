import React from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract, ethers, Signer } from "ethers";
import {
  ChangeEvent,
  MouseEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import CarbonArtifact from "../../../artifacts/contracts/CarbonContract.sol/CarbonContract.json";
import { Provider } from "../utils/provider";
import { SectionDivider } from "./SectionDivider";
import axios, { BASE_URL, BASE_URL_BLOCKCHAIN } from "../../../api/config";
import { WHITELIST } from "./Whitelister";
import { Button } from "@material-ui/core";
import AlertDialog from "../../common/ReduxBased/confirmationDialog/container";

export const CARBON = "Carbon Credit";

export default function CarbonContract(props: any): ReactElement {
  const context = useWeb3React<Provider>();
  const { library, active } = context;
  const [signer, setSigner] = useState<Signer>();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  const [address, setAddress] = useState(props.address || "");
  const { id, whiteListContractAddress } = props;
  const [values, setValues] = useState({
    uri: "",
    retireAddress: "",
  });
  const [showInitializeForm, setShowInitializeForm] = useState(false);
  const { onDeploy = () => {} } = props;

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleUriFileChange = async (e: any) => {
    const file = e.target.files[0];
    if (!file) {
      return false;
    }
    try {
      const formData = new FormData();
      formData.append("file", file);
      const { data: res } = await axios.post(
        BASE_URL_BLOCKCHAIN + "/upload",
        formData
      );
      if (res) {
        alert("Uri file uploaded successfully");
        console.log("uri", res);
        setValues((prev) => ({ ...prev, uri: res.hash }));
      }
    } catch (ex: any) {
      console.error(ex.message);
      alert(ex.message);
    }
  };

  const initialzeContract = (carbonContract: any) => {
    if (!values.uri || !values.retireAddress) {
      alert("URI and Retire address both are required");
      return false;
    }
    async function initialize() {
      try {
        const Carbon = new ethers.ContractFactory(
          CarbonArtifact.abi,
          CarbonArtifact.bytecode,
          signer
        );
        const contract = Carbon.attach(address);
        const res = await contract.carbonInitialize(
          whiteListContractAddress,
          values.uri,
          values.retireAddress
        );
        if (res) {
          alert("Carbon contract has been initialized successfully");
          localStorage.setItem("CC__INIT", "true");
          setShowInitializeForm(false);
          setInitialized(true);
        }
      } catch (error: any) {
        console.error(error);
        window.alert(
          "Error!" +
            (error && error.data
              ? `\n\n${error.data.message}`
              : `\n\n${error.message}`)
        );
      }
    }
    initialize();
  };

  useEffect((): void => {
    if (!library) {
      setSigner(undefined);
      return;
    }

    setSigner(library.getSigner());
  }, [library]);

  const updateContractAddress = async (id: any, address: string) => {
    try {
      const { data: res } = await axios.post("/blockchain/contract-address", {
        contractId: id,
        address,
        isAddressConstant: true,
      });
      return res;
    } catch (ex) {
      throw ex;
    }
  };
  useEffect(() => {
    const postContractInList = async (data: any) => {
      try {
        const { data: res } = await axios.post("/blockchain/contract", data);
        return res;
      } catch (ex) {
        throw ex;
      }
    };
    const getContractsList = async () => {
      try {
        const { data: res } = await axios.get("/blockchain/contract/list");
        return res;
      } catch (ex) {
        throw ex;
      }
    };
    const getContractDetails = async (contractId: any) => {
      try {
        const { data: res } = await axios.get(
          "/blockchain/contract-address?contractId=" + contractId
        );
        return res;
      } catch (ex) {
        throw ex;
      }
    };
    const fetchCarbonContractAddress = async () => {
      try {
        const contracts = await getContractsList();
        const carbonContract = contracts.find((f: any) => f.code === CARBON);
        const whiteListContract = contracts.find(
          (f: any) => f.code === WHITELIST
        );
        if (!whiteListContract) {
          return false;
        } else {
          const contract = await getContractDetails(whiteListContract.id);
          setAddress(contract.address);
        }
        if (!carbonContract) {
          const { id } = await postContractInList({
            name: CARBON,
            code: CARBON,
          });
        } else {
          const contract = await getContractDetails(carbonContract.id);
          // setCarbonContractAddress(contract.address);
        }
      } catch (ex) {
        throw ex;
      }
    };

    try {
      // fetchCarbonContractAddress();
    } catch (ex: any) {
      console.error("Error in fetching white list contract", ex.message);
    }
  }, []);

  function handleDeployContract(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    // only deploy the Greeter contract one time, when a signer is defined
    if (address || !signer) {
      return;
    }

    async function deployCarbonContract(signer: Signer): Promise<void> {
      setIsLoading(true);
      const Carbon = new ethers.ContractFactory(
        CarbonArtifact.abi,
        CarbonArtifact.bytecode,
        signer
      );

      try {
        const carbonContract = await Carbon.deploy();
        await carbonContract.deployed();
        window.alert(`Carbon deployed to: ${carbonContract.address}`);
        setAddress(carbonContract.address);
        await updateContractAddress(id, carbonContract.address);
        onDeploy();
      } catch (error: any) {
        window.alert(
          "Error!" +
            (error && error.data
              ? `\n\n${error.data.message}`
              : `\n\n${error.message}`)
        );
      }
      setIsLoading(false);
    }

    deployCarbonContract(signer);
  }

  if (!whiteListContractAddress || !active) {
    return <></>;
  }

  return (
    <>
      {address && <StyledLabel>Deployed</StyledLabel>}
      {!address ? (
        <Button onClick={handleDeployContract} disabled={isLoading}>
          {isLoading ? "Please wait..." : "Deploy"}
        </Button>
      ) : (
        <>
          {/* {isInitialized ? (
            <StyledLabel>Initialized</StyledLabel>
          ) : ( */}
          <Button
            disabled={isLoading}
            onClick={() => setShowInitializeForm(true)}
          >
            {isLoading ? "Please wait..." : "Initialize"}
          </Button>
          {/* )} */}
        </>
      )}

      <AlertDialog
        maxWidth="sm"
        open={showInitializeForm}
        showTitle
        title="Initialize Carbon Credits Contract"
        cancelButtonText={"Close"}
        cancelAlertDialog={() => setShowInitializeForm(false)}
        closeAlertDialog={() => setShowInitializeForm(false)}
        hideApplyButton
        message={
          <>
            <FormControl>
              <label>URI</label>
              <input type="file" onChange={handleUriFileChange} />
            </FormControl>
            <p style={{ textAlign: "right" }}>{values.uri}</p>
            <FormControl>
              <label>Retire Address</label>
              <input
                onChange={handleChange}
                name="retireAddress"
                value={values["retireAddress"]}
              />
            </FormControl>
            <StyledDeployContractButton onClick={initialzeContract}>
              Initialize Carbon Contract
            </StyledDeployContractButton>
          </>
        }
      />
    </>
  );
}

const StyledLabel = styled.label`
  font-weight: bold;
  display: block;
  margin-top: 1em;
`;
const StyledDeployContractButton = styled.button`
  width: 180px;
  height: 2rem;
  border-radius: 1rem;
  border-color: blue;
  cursor: pointer;
  place-self: center;
`;

const StyledCarbonDiv = styled.div`
  margin-top: 2em;
`;

const FormControl = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  label {
    min-width: 150px;
    display: inline-block;
  }
  input {
    flex: 1;
    border: none;
    background: none;
    border: 1px solid black;
    padding: 10px;
    border-radius: 4px;
  }
`;
