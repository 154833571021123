import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  Select,
} from "@material-ui/core";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
// import EditSeller from "../EditSeller/container"
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import instance, { BASE_URL_PROJECT } from "../../../../../api/config";
import VintageDetails from "./VintageDetails/container";
import ViewProjectDetails from "./ProjectDetails/container";
// import ViewAllCompanyDetails from "./CompanyDetails/container";

// import ChangeCompany from "./changeCompany"

toast.configure();
const ChangeProjectStatus = (props) => {
  const [value, setValue] = React.useState(props.status || "");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const { popupStatusId, selectedIds } = props;
  let data;

  popupStatusId !== ""
    ? (data = {
        id: popupStatusId,
        status: value,
      })
    : selectedIds.map((id, index) => {
        data = {
          id: id,
          status: value,
        };
      });

  const handleSave = () => {
    instance
      .put(BASE_URL_PROJECT + "/project/onboarding/status-change", data)
      .then((res) => {
        window.location.replace("/user/project/index");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <Grid item xs={12} md={12}>
      <div style={{ textAlign: "center" }}>
        <InputLabel id="projectStatusLabel">Select Project Status</InputLabel>
        <Select
          labelId="projectStatusLabel"
          id="projectStatus"
          value={value}
          onChange={handleChange}
        >
          <MenuItem disabled value={"PROJECT_AWAITING_APPROVAL"}>
            PROJECT_AWAITING_APPROVAL
          </MenuItem>
          <MenuItem value={"PROJECT_REJECTED"}>PROJECT_REJECTED</MenuItem>
          <MenuItem value={"PROJECT_APPROVED"}>PROJECT_APPROVED</MenuItem>
          <MenuItem disabled value={"PROJECT_PUBLISHING_REQUESTED"}>
            PROJECT_PUBLISHING_REQUESTED
          </MenuItem>
          <MenuItem value={"PROJECT_LIVE"}>PROJECT_LIVE</MenuItem>
          <MenuItem value={"PROJECT_SOLD"}>PROJECT_SOLD</MenuItem>
          <MenuItem value={"PROJECT_ONHOLD"}>PROJECT_ONHOLD</MenuItem>
          <MenuItem value={"DRAFT"}>DRAFT</MenuItem>
        </Select>
      </div>
      {!props.selectedIds.length ||
        (!popupStatusId && (
          <div style={{ color: "red", fontSize: "12px", textAlign: "center" }}>
            Please select rows from table
          </div>
        ))}
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ background: "#1D5485", color: "white", marginTop: "10px" }}
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </div>
    </Grid>
  );
};

class ViewAllProjects extends Component {
  state = {
    show: false,
    showDelete: false,
    showEdit: false,
    changeProjectStatus: false,
    showDetails: false,
    selectedSellerId: "",
    showChangeCompanyPopup: false,
    currentId: "",
    currentEmail: "",
    anchorEl: null,
    resendebayUsername: "",
    resendebayEmail: "",
    popupStatusId: "",
    canMint: false,
    selectedProject: null,
  };

  showAlertDialog = (value, id, data) => {
    this.setState({
      show: value,
      currentId: id,
      currentEmail: data[2],
    });
  };

  handleClick = (event, dataValue, rowData) => {
    this.setState({
      anchorEl: event.currentTarget,
      currentId: dataValue,
      editData: rowData,
      resendebayUsername: rowData[3],
      resendebayEmail: rowData[4],
      canMint: rowData[4] === "PROJECT_PUBLISHING_REQUESTED" ? true : false,
      status: rowData[4],
    });
  };
  showAlertDialogClose = (value, id) => {
    this.setState({
      showEdit: value,
    });
  };

  showAlertDialogClosed = (value, id) => {
    this.setState({
      show: value,
      showDetails: value,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialogDelete = (value, id) => {
    this.setState({
      showDelete: value,
      currentId: id,
    });
  };
  handleMint = (value, id, data) => {
    this.setState({
      showMintDialog: value,
      currentId: id,
      data,
    });
  };

  deleteProfileHandler = () => {
    this.setState({
      showDelete: false,
    });
    this.props.deleteSellerHandler(this.state.currentId);
  };

  editCompany = (show, id, editData) => {
    console.log(show, id);
    this.setState({
      selectedSellerId: id,
      showChangeCompanyPopup: show,
    });
  };

  editHandler = (show, id, editData) => {
    console.log(id);
    this.props.editSellerChangeHandler(id, editData);
    this.setState({
      showEdit: show,
    });
  };

  showEditSellerDialog = (value) => {
    this.setState({
      showEdit: value,
    });
  };

  resendInvitationHandler = (username, email) => {
    this.props.resendInvitation(username, email);
  };

  showDetailsAlertDialog = (value, id, data) => {
    this.setState({
      showDetails: value,
      currentId: id,
      currentEmail: data[2],
    });
  };

  componentDidMount = () => {
    this.props.checkIfUrlIsAllowed();
    this.props.fetchDataIfNeeded();
  };

  selectedIds = [];
  customToolbarSelect = (selectedRows, displayData) => {
    let ids = [];
    selectedRows.data.map((value, index) => {
      let selectedValue = displayData.filter(
        (_, dataIndex) => dataIndex === value.dataIndex
      );
      ids.push(selectedValue[0].data[0]);
    });
    this.selectedIds = ids;
  };

  render() {
    const {
      classes,
      allSellers,
      sellerAddStatus,
      addSellerResponseMsg,
      isAllowed,
      carbonContractAddress,
      whiteListContractAddress,
    } = this.props;
    const {
      currentId,
      editData,
      resendebayUsername,
      resendebayEmail,
      canMint,
    } = this.state;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    const columns = [
      "Project ID",
      "Project Name",
      "Seller ID",
      "Modified Date",
      "Status",
      {
        name: "Action",
        label: "Action",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            let rowData = data.rowData;
            return (
              <div>
                <Button
                  aria-describedby={id}
                  variant="contained"
                  color="primary"
                  onClick={(e) => this.handleClick(e, rowDataValue, rowData)}
                >
                  Actions
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className={classes.actions}>
                    {/* <Button color="primary" variant="outlined" onClick={() => this.showAlertDialog(true, currentId, editData)}>Company Profile</Button> */}
                    {/* <Button color="primary" variant="outlined" onClick={()=>this.editCompany(true, currentId, editData)}>Change company</Button>
                   
                   <Button color="primary" variant="outlined" onClick={()=>this.editHandler(true, currentId, editData)}>Edit</Button> */}

                    {canMint && (
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() =>
                          this.handleMint(true, currentId, editData)
                        }
                      >
                        Mint
                      </Button>
                    )}

                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.props.history.push(`/user/project/${currentId}`)
                      }
                    >
                      View Detail
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.setState({
                          changeProjectStatus: !this.state.changeProjectStatus,
                          popupStatusId: editData[0],
                        })
                      }
                    >
                      Change Status
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.showAlertDialogDelete(true, currentId)
                      }
                    >
                      Delete
                    </Button>
                  </div>
                </Popover>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      // filterType: 'checkbox',
      customToolbarSelect: this.customToolbarSelect,
    };
    console.log("status", this.state.status);

    return (
      <>
        {this.state.showDelete && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showDelete}
            showTitle={true}
            title={"Delete Seller"}
            message={"Are you sure want to delete this seller?"}
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogDelete(false)}
            closeAlertDialog={() => this.showAlertDialogDelete(false)}
            applyAlertDialog={() => this.deleteProfileHandler()}
          />
        )}
        {/* {
                this.state.showEdit &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showEdit}
                    showTitle={true}
                    title={"Edit Seller"}
                    message={<EditSeller />}
                    hideApplyButton={true}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showEditSellerDialog(false)}
                    closeAlertDialog={() => this.showEditSellerDialog(false)}
                    applyAlertDialog={() => this.showEditSellerDialog(false)}

                />
            } */}
        {this.state.showMintDialog && (
          <AlertDialog
            maxWidth={"lg"}
            open={this.state.showMintDialog}
            showTitle={true}
            title={"Mint Project"}
            message={<VintageDetails projectId={this.state.currentId} />}
            hideApplyButton={true}
            cancelButtonText={"Close"}
            cancelAlertDialog={() =>
              this.setState({
                showMintDialog: false,
              })
            }
            closeAlertDialog={() =>
              this.setState({
                showMintDialog: false,
              })
            }
            applyAlertDialog={() => {}}
          />
        )}

        {this.state.changeProjectStatus && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.changeProjectStatus}
            showTitle={true}
            title={"Edit Project Status"}
            message={
              <ChangeProjectStatus
                selectedIds={this.selectedIds}
                popupStatusId={this.state.popupStatusId}
                status={this.state.status}
              />
            }
            hideApplyButton={true}
            cancelButtonText={"Close"}
            cancelAlertDialog={() =>
              this.setState({
                changeProjectStatus: !this.state.changeProjectStatus,
              })
            }
            closeAlertDialog={() =>
              this.setState({
                changeProjectStatus: !this.state.changeProjectStatus,
              })
            }
            applyAlertDialog={() => this.showEditSellerDialog(false)}
          />
        )}
        {/* {
                     this.state.showChangeCompanyPopup &&
                     <AlertDialog
                         maxWidth={"sm"}
                         open={this.state.showChangeCompanyPopup}
                         showTitle={true}
                         title={"Change Company"}
                         message={<ChangeCompany selectedIds={this.state.selectedSellerId}/>}
                         hideApplyButton={true}
                         cancelButtonText={"Close"}
                         cancelAlertDialog={() => this.setState({showChangeCompanyPopup : !this.state.showChangeCompanyPopup})}
                         closeAlertDialog={() => this.setState({showChangeCompanyPopup : !this.state.showChangeCompanyPopup})}
                         applyAlertDialog={() => this.showEditSellerDialog(false)}
                     />
                 } */}

        {/* {
                    this.state.show &&
                    <AlertDialog
                        maxWidth={"lg"}
                        open={this.state.show}
                        showTitle={false}
                        hideApplyButton={true}
                        message={<ViewAllCompanyDetails currentId={this.state.currentId} currentEmail={this.state.currentEmail} showAlertDialog={this.showAlertDialog} />}
                        cancelButtonText={"Close"}
                        cancelAlertDialog={() => this.showAlertDialogClosed(false)}
                        closeAlertDialog={() => this.showAlertDialogClosed(false)}
                        applyAlertDialog={() => this.showAlertDialogClosed(false)}
                    />
                } */}

        {this.state.showDetails && (
          <AlertDialog
            maxWidth={"lg"}
            open={this.state.showDetails}
            showTitle={false}
            hideApplyButton={true}
            message={
              <ViewProjectDetails
                currentId={this.state.currentId}
                currentEmail={this.state.currentEmail}
                showDetailsAlertDialog={this.showDetailsAlertDialog}
              />
            }
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogClosed(false)}
            closeAlertDialog={() => this.showAlertDialogClosed(false)}
            applyAlertDialog={() => this.showAlertDialogClosed(false)}
          />
        )}

        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            {addSellerResponseMsg ? (
              <Alert severity="success">{sellerAddStatus}</Alert>
            ) : null}
            {/* <div style={{float:"right"}}>
                            <Button style={{background:"#1D5485", color:"white"}} onClick={()=>this.setState({changeProjectStatus : !this.state.changeProjectStatus})}>Change Seller Status</Button>
                        </div> */}
            <p>View all Projects</p>
            <MUIDataTable
              title={"All Projects"}
              data={allSellers}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(ViewAllProjects);
