import {
    SELLER_PAGE_TEXT_CHANGE_HANDLER,
    // BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER,
    PROFILE_SAVE_ALL_SELLER_PROFILES,
    // BIDDING_PROFILE_PAGE_SAVE_ID_HANDLER,
    // BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
    SELLER_EDIT_TEXT_CHANGE_HANDLER,
    VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER,
    SELLER_SESSION_TOKEN,
    SELLER_SESSION_SAVE,
    SELLER_TEXT_CHANGE_HANDLER,
    ACTIVITY_TOTAL_RECORDS,
} from "./actions";

export const activityReducer = (state = [], action) => {
    switch (action.type) {

        case SELLER_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    sellerForm: {
                        ...state.forms.sellerForm,
                        fields: {
                            ...state.forms.sellerForm.fields,
                            [action.key]: {
                                ...state.forms.sellerForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case ACTIVITY_TOTAL_RECORDS: {
            return {
              ...state,
              data: {
                ...state.data,
                totalCountsActivity: action.payload,
              },
            };
        }

        case SELLER_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    sellerFormEdit: {
                        ...state.forms.sellerFormEdit,
                        fields: {
                            ...state.forms.sellerFormEdit.fields,
                            [action.key]: {
                                ...state.forms.sellerFormEdit.fields[action.key],
                                value:action.payload
                            }
                        }
                    }
                }
            };
        }

        case PROFILE_SAVE_ALL_SELLER_PROFILES: {
            return {
                ...state,
                data: {
                    ...state.data,
                    allSellers: [...action.payload]
                }
            }
        }

        case SELLER_SESSION_TOKEN: {
            return {
                ...state,
                data: {
                    ...state.data,
                    sellerTokenRes: action.payloadSessionID,
                    fetchTokenStatus:action.payloadFetchTokenMessage
                }
            }
        }

        case SELLER_SESSION_SAVE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    addSellerResponseMsg: action.payload,
                    sellerAddStatus: action.payloadAddSellerMessage

                }
            }
        }


        case SELLER_EDIT_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                editData: {
                    ...state.editData,
                    editDataId: action.key,
                    editDataFirstname:action.payloadFirstname,
                    editDataLastname:action.payloadLastname,
                    editDataUserId:action.payloadUserId,
                    editDataEmail:action.payloadEmail,
                }
            }
        }

        case VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                editViewData: {
                    ...state.editData,
                    editDataId: action.key,
                    editCurrentDataId: action.viewCurrentKey,
                    editstartBiddingPrice:action.payloadstartBiddingPrice,
                    editendBiddingPrice:action.payloadendBiddingPrice,
                    editfirstBiddingIncrement:action.payloadfirstBiddingIncrement,
                    editsecondBiddingIncrement:action.payloadsecondBiddingIncrement,
                }
            }
        }

        default: {
            return {
                ...state
            };
        }
    }
}