import React, { Component, useEffect } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Redirect, Switch } from "react-router-dom";
import PrivateRoute from "../../common/PrivateRouteContainer";
import DashboardLanding from "./Landing/container";
import MyAccount from "./MyAccount/container";
import LoginHeader from "../../OmniConnect/Dashboard/common/Header/container";
import Footer from "../../common/Footer/component";
import ViewAllAccountType from "./AccountType/AllAccountType/container";
import CreateAccountType from "./AccountType/CreateAccountType/container";
import ViewAllUserAccounts from "./UserAccount/AllUserAccount/container";
import CreateUserAccount from "./UserAccount/CreateUserAccount/container";
import ViewAllAccounts from "./Accounts/AllAccounts/container";
import CreateAccount from "./Accounts/CreateAccount/container";
import AllLanguages from "./Languages/container";
import CreateLanguage from "./Languages/CreateLanguage/container";
import Logout from "../../Logout/container";
import CreateSeller from "./SellerManagement/CreateSeller/container";
import CreateBuyer from "./BuyerManagement/CreateBuyer/container";
import CreateProject from "./ProjectManagement/CreateProject/container";
import CreateProjectType from "./ProjectTypeManagement/CreateProjectType/container";
import ViewAllSeller from "./SellerManagement/ViewAllSeller/container";
import ViewAllBuyers from "./BuyerManagement/ViewAllBuyers/container";
import ViewAllProjects from "./ProjectManagement/ViewAllProjects/container";
import ViewProjectDetail from "./ProjectManagement/ViewProjectDetail/component";
import ViewAllProjectsInProgress from "./ProjectManagement/ViewProjectsInProgress/container";
import ViewBiddingInProgress from "./ProjectManagement/ViewBiddingInProgress/container";
import ViewClosedBidding from "./ProjectManagement/ViewClosedBidding/container";
import ViewClosedBiddingByProjectID from "./ProjectManagement/ViewClosedBiddingByProjectID/container";
import IndexProjectType from "./ProjectTypeManagement/IndexProjectType/container";
import ViewRequestLogs from "./requestLogsManagement/ViewAllSeller/container";
import ViewErrorLogs from "./errorLogsManagement/ViewAllSeller/container";
import ViewActivityLogs from "./activityLogsManagement/ViewAllActivity/container"
import ViewImpactInvestors from "./Investors/ViewInvestors/container";
import ViewAllInvestments from "./Investments/ViewAllInvestments/container";
import instance, { BASE_URL_USER } from "../../../api/config";
import ls from "local-storage";

// roles and permissions
import IndexProjectMainAttribute from "./ProjectMainAttributeManagement/IndexProjectMainAttribute/container";
import CreateProjectMainAttribute from "./ProjectMainAttributeManagement/CreateProjectMainAttribute/container";
import IndexProjectAttribute from "./ProjectAttributeManagement/IndexProjectAttribute/container";
import CreateProjectAttribute from "./ProjectAttributeManagement/CreateProjectAttribute/container";
// roles and permissions
import AddNewRoles from "./Permissions/Roles/AddNew/container";
import ViewAllRoles from "./Permissions/Roles/ViewAll/container";
import AddNewPermissions from "./Permissions/Permissions/AddNew/container";
import ViewAllPermissions from "./Permissions/Permissions/ViewAll/container";

import ViewAllContracts from "./BlockChainManagement/ViewAllContracts/container";
import ViewAllContent from "./ContentManagement/ViewAllContent/container";
import PremiumRate from "./ContentManagement/PremiumRate/container";

import { AbstractConnector } from "@web3-react/abstract-connector";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError,
} from "@web3-react/injected-connector";
import { injected } from "../../WEB3/utils/connectors";
import { useEagerConnect, useInactiveListener } from "../../WEB3/utils/hooks";

const Connected = ({ children }) => {
  const eagerConnectionSuccessful = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider,
  // if it exists
  useInactiveListener(!eagerConnectionSuccessful);
  return <>{children}</>;
};

class UserDashboard extends Component {
  componentDidMount = () => {
    /*
        let data = {
            params: {
            accountTypeId: ls.get('AfterLoginAccountTypeid')
        }}
       let AfterLoginAccountTypeid= ls.get('AfterLoginAccountTypeid');

      instance.post(BASE_URL_USER+'apps/getAppActions', {}, data)
      .then(res => {
        const EventFilter = res.data.data.filter( value => {
            return AfterLoginAccountTypeid === AfterLoginAccountTypeid && value.appName === "eventManagement"
        });
        const UserFilter = res.data.data.filter( value => {
            return AfterLoginAccountTypeid === AfterLoginAccountTypeid && value.appName === "userManagement"
        });
        const SellerFilter = res.data.data.filter( value => {
            return AfterLoginAccountTypeid === AfterLoginAccountTypeid && value.appName === "sellerManagement"
        });

        // ls.set('AclGetAccountTypedata', EventFilter[0].accountTypes.coreAccountType);
        ls.set('AclGetEventAlloweddata', EventFilter[0].isAllowed);
        ls.set('AclGetUserAlloweddata', UserFilter[0].isAllowed);
        ls.set('AclGetSellerAlloweddata', SellerFilter[0].isAllowed);
      })
      .catch(error => {
          console.log(error, "ACL Load Api Error")
      })
      */
  };

  render() {
    return (
      <>
        <LoginHeader />
        <Switch>
          <PrivateRoute path={`/user/dashboard`} component={DashboardLanding} />
          <PrivateRoute path={`/user/myaccount`} component={MyAccount} />
          <PrivateRoute
            path={`/user/all-accounttype`}
            component={ViewAllAccountType}
          />
          <PrivateRoute
            path={`/user/create-usertype`}
            component={CreateAccountType}
          />
          <PrivateRoute
            path={`/user/all-useraccount`}
            component={ViewAllUserAccounts}
          />
          <PrivateRoute
            path={`/user/create-useraccount`}
            component={CreateUserAccount}
          />
          <PrivateRoute
            path={`/user/all-accounts`}
            component={ViewAllAccounts}
          />
          <PrivateRoute
            path={`/user/create-account`}
            component={CreateAccount}
          />
          <PrivateRoute path={`/user/logout`} component={Logout} />
          <PrivateRoute path={`/user/languages`} component={AllLanguages} />
          <PrivateRoute
            path={`/user/create-language`}
            component={CreateLanguage}
          />
          <PrivateRoute path={`/user/seller/create`} component={CreateSeller} />
          <PrivateRoute path={`/user/buyer/create`} component={CreateBuyer} />
          <PrivateRoute path={`/user/all-sellers`} component={ViewAllSeller} />
          <PrivateRoute path={`/user/all-buyers`} component={ViewAllBuyers} />
          <PrivateRoute
            path={`/user/request-seller-logs`}
            component={ViewRequestLogs}
          />
          <PrivateRoute
            path={`/user/error-seller-logs`}
            component={ViewErrorLogs}
          />
          <PrivateRoute
            path={`/user/activity-seller-logs`}
            component={ViewActivityLogs}
          />
          <PrivateRoute
            path={`/user/all-accounttype`}
            component={ViewAllAccountType}
          />
          <PrivateRoute
            path={`/user/create-usertype`}
            component={CreateAccountType}
          />
          <PrivateRoute
            path={`/user/all-useraccount`}
            component={ViewAllUserAccounts}
          />
          <PrivateRoute
            path={`/user/create-useraccount`}
            component={CreateUserAccount}
          />
          <PrivateRoute
            path={`/user/all-accounts`}
            component={ViewAllAccounts}
          />
          <PrivateRoute
            path={`/user/create-account`}
            component={CreateAccount}
          />
          <PrivateRoute
            path={`/user/all-permission`}
            component={ViewAllPermissions}
          />

          <PrivateRoute path={`/user/role-new`} component={AddNewRoles} />
          <PrivateRoute path={`/user/role-all`} component={ViewAllRoles} />
          <PrivateRoute
            path={`/user/permissions-new`}
            component={AddNewPermissions}
          />
          <PrivateRoute
            path={`/user/permissions-all`}
            component={ViewAllPermissions}
          />
          <PrivateRoute
            path={`/user/project/index`}
            component={ViewAllProjects}
          />
          <PrivateRoute
            path={`/user/project/:projectId`}
            component={ViewProjectDetail}
          />
          <PrivateRoute
            path={`/user/project/create`}
            component={CreateProject}
          />
          <PrivateRoute
            path={`/user/project/project-type/index`}
            component={IndexProjectType}
          />
          <PrivateRoute
            path={`/user/project/project-type/create`}
            component={CreateProjectType}
          />
          <PrivateRoute
            path={`/user/project/main-attribute/index`}
            component={IndexProjectMainAttribute}
          />
          <PrivateRoute
            path={`/user/project/main-attribute/create`}
            component={CreateProjectMainAttribute}
          />
          <PrivateRoute
            path={`/user/project/projects-in-progress`}
            component={ViewAllProjectsInProgress}
          />
          <PrivateRoute
            path={`/user/project/bidding/:projectId`}
            component={ViewBiddingInProgress}
          />
          <PrivateRoute
            path={`/user/project/closed-projects`}
            component={ViewClosedBidding}
          />
          <PrivateRoute
            path={`/user/project/closed-bidding/index/:projectId`}
            component={ViewClosedBiddingByProjectID}
          />
          <PrivateRoute
            path={`/user/project/project-attribute/index/:projectTypeId`}
            component={IndexProjectAttribute}
          />
          <PrivateRoute
            path={`/user/project/project-attribute/create/:projectTypeId`}
            component={CreateProjectAttribute}
          />
          <PrivateRoute
            path={`/user/blockchain/projects/shares`}
            component={ViewAllProjects}
          />
          <PrivateRoute
            path={`/user/blockchain/projects/credits`}
            component={ViewAllProjects}
          />
          <PrivateRoute
            path={`/user/all-investors`}
            component={ViewImpactInvestors}
          />
          <PrivateRoute
            path={`/user/blockchain/contracts/index`}
            component={ViewAllContracts}
          />
          <PrivateRoute
            path={`/user/content-management/index`}
            component={ViewAllContent}
          />
          <PrivateRoute
            path={`/user/content-management/premium`}
            component={PremiumRate}
          />
          <PrivateRoute
            path={`/user/all-investments`}
            component={ViewAllInvestments}
          />
          <Redirect to="/user/dashboard" />
        </Switch>
        <Footer />
      </>
    );
  }
}

const StyledUserDashboard = ({ fetchContracts }) => {
  useEffect(() => {
    fetchContracts();
  }, []);
  return (
    <Connected>
      <UserDashboard />
    </Connected>
  );
};

export default withStyles(styles)(StyledUserDashboard);
